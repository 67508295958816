export const defaultMeta = [
  {
    vmid: 'author',
    name: 'author',
    content: 'TalkPlus',
  },
  {
    vmid: 'description',
    name: 'description',
    content:
      '톡플러스는 채팅 기능을 손쉽게 추가 할 수 있는 API와 SDK를 제공하는 기업용 채팅 솔루션 입니다.',
  },
  {
    vmid: 'keywords',
    name: 'keywords',
    content:
      '톡플러스, talkplus, chat  API, chat SDK, 채팅 API, 채팅 SDK, 채팅 솔루션, 채팅서비스 구현',
  },
];
