<template>
  <div :style="{ height: notificationsHeight + 'px' }" id="noticebarWrap">
    <v-system-bar
      app
      class="noticebar-wrap"
      v-show="hasNotifications"
      :style="{ height: notificationsHeight + 'px' }"
      id="noticebarWrapInner"
    >
      <div class="noticebar" v-for="(item, index) in notifications" :key="item.text" :id="'notice_' + index">
        <div v-if="item.type === 'FreePlanExpiring'" class="noticebar-inner bg-blue">
          <div class="textbox">
            <span class="text icon-info-white">{{ item.text }}</span>
            <v-btn
              depressed
              rounded
              class="font-weight-regular ml-2 btn-notice btn-link-blue"
              dark
              @click.stop="paidStart"
            >
              {{ $t('DASHBOARD_BTN1') }}
            </v-btn>
          </div>
          <button icon dense class="btn-close" @click.stop="closeNoticebar(index)">
            <v-icon size="15" color="#ffffff">close</v-icon>
          </button>
        </div>
        <div
          v-else-if="item.type === 'CardNotRegistered' || item.type === 'CardPaymentFailed'"
          class="noticebar-inner bg-red"
        >
          <div class="textbox">
            <span class="text icon-payment">{{ item.text }}</span>
            <v-btn depressed rounded class="font-weight-regular ml-2 btn-notice btn-link-red" dark @click="goBilling">
              {{ $t('DASHBOARD_BTN2') }}
            </v-btn>
          </div>
          <button icon dense class="btn-close" @click.stop="closeNoticebar(index)">
            <v-icon size="15" color="#ffffff">close</v-icon>
          </button>
        </div>
        <div v-else-if="item.type === 'UsingFreePlan'" class="noticebar-inner bg-blue">
          <div class="textbox ">
            <span class="text icon-info-white">{{ item.text }}</span>
          </div>
          <button icon dense class="btn-close" @click.stop="closeNoticebar(index)">
            <v-icon size="15" color="#ffffff">close</v-icon>
          </button>
        </div>
        <div v-else-if="item.type === 'PasswordResetRequired'" class="noticebar-inner bg-red">
          <div class="textbox ">
            <span class="text icon-info-white">{{ item.text }}</span>
          </div>
          <button icon dense class="btn-close" @click.stop="closeNoticebar(index)">
            <v-icon size="15" color="#ffffff">close</v-icon>
          </button>
        </div>
        <div v-else class="noticebar-inner bg-blue">
          <div class="textbox ">
            <span class="text icon-notice">{{ item.text }}</span>
          </div>
          <button icon dense class="btn-close" @click.stop="closeNoticebar(index)">
            <v-icon size="15" color="#ffffff">close</v-icon>
          </button>
        </div>
      </div>
    </v-system-bar>
    <ApplyService
      v-model="applyService"
      :startInfo="this.startInfo"
      @apply:paidPlan="updateNextPlan"
      @error:paidPlan="errorNextPlan"
    ></ApplyService>
    <v-snackbar v-model="showResult" :timeout="5000" top right elevation="24" style="z-index:1000000!important;">
      {{ logMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="showResult = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import planAPI from '@/api/planAPI';
import ApplyService from '@/components/modal/ApplyServiceModal.vue';
import bus from '@/utils/bus.js';
export default {
  props: {
    //notifications: Array,
    //height: Number,
  },
  components: {
    ApplyService,
  },
  data: () => ({
    applyService: false,
    showResult: false,
    logMessage: '',
    startInfo: {},
  }),
  computed: {
    notifications() {
      return this.$store.state.notifications;
    },
    notificationsHeight() {
      return this.$store.getters.noticebarHeight;
    },
    hasNotifications() {
      return this.$store.getters.showNoticeBar;
    },
  },
  methods: {
    closeNoticebar(index) {
      const containerNotice = document.getElementById('notice_' + index);
      let rowNoticeHeight = containerNotice.offsetHeight;
      containerNotice.style.display = 'none';

      //전체 notice-wrap의 높이 줄이기
      const noticeWrap = document.getElementById('noticebarWrap');
      const noticeWrapInner = document.getElementById('noticebarWrapInner');
      let noticeWrapHeight = parseInt(noticeWrap.style.height.slice(0, -2));
      let customNoticeWrapHeight = noticeWrapHeight - rowNoticeHeight;

      //this.$emit('height:notice', customNoticeWrapHeight);

      noticeWrap.style.height = customNoticeWrapHeight + 'px';
      noticeWrapInner.style.height = customNoticeWrapHeight + 'px';

      //header, nav, main 줄이기
      const header = document.getElementsByTagName('header')[0];

      const main = document.getElementsByTagName('main')[0];
      const nav = document.getElementsByTagName('nav')[0];

      let originHeaderTop = parseInt(header.style.marginTop.slice(0, -2));
      let originMainTop = parseInt(main.style.paddingTop.slice(0, -2));
      let originNavTop = parseInt(nav.style.top.slice(0, -2));

      let customHeaderTop = originHeaderTop - rowNoticeHeight;
      let customMainTop = originMainTop - rowNoticeHeight;
      let customNavTop = originNavTop - rowNoticeHeight;

      header.style.marginTop = customHeaderTop + 'px';
      main.style.paddingTop = customMainTop + 'px';
      nav.style.top = customNavTop + 'px';
      nav.style.maxHeight = window.innerHeight - customNavTop + 'px';
    },

    goBilling() {
      this.$router.push({ name: 'bills' }).catch(() => {});
    },
    async fetchNoticeData() {
      try {
        await this.$store.dispatch('FETCH_NOTICE');
      } catch (error) {
        console.log('error', error.response);
      }
    },
    async startPaidInfo() {
      try {
        const { data } = await planAPI.startPaidPlan();
        this.startInfo = data;
        //this.clear();
      } catch (error) {
        console.log(error);
      }
    },
    paidStart() {
      this.startPaidInfo();
      this.applyService = true;
    },
    updateNextPlan(data) {
      bus.startSpinner();
      this.$router.push('/bills').catch(() => {});
      location.reload();
    },

    errorNextPlan(data) {
      this.applyService = false;
      this.showResult = true;
      this.logMessage = this.$i18n.t(`ERROR_${data?.code}`);
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped lang="scss">
.v-system-bar {
  display: block;
  padding: 0;
}
.noticebar-wrap {
  box-sizing: border-box;
}
.noticebar {
  .noticebar-inner {
    padding: 10px 40px 10px 10px;
    min-height: 44px;
    position: relative;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    line-height: 24px;
    &.bg-blue {
      background: #2c45d0 !important;
    }
    &.bg-green {
      background: #20c100 !important;
    }
    &.bg-pink {
      background: #ff00b8 !important;
    }
    &.bg-red {
      background: #ff0008 !important;
    }
  }

  .textbox {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;

    .text {
      display: inline-block;
      //position: relative;
      //top: 2px;
      line-height: 24px;
      padding: 0 0 0 24px;
    }

    .btn-notice {
      border: 1px solid #fff !important;
      font-size: 10px;
      line-height: 24px;
      .v-btn__content {
        line-height: 24px !important;
      }
    }
  }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 8px;
    i {
      color: #fff;
      width: 20px;
      height: 20px;
    }
  }
  .btn-link-red {
    background-color: red;
    color: white;
    height: 23px;
  }

  .btn-link-blue {
    background-color: #2c45d0;
    color: white;
    height: 23px;
  }
}
</style>
