<template>
  <v-footer height="">
    <div class="footer-wrap">
      <ul class="footer-info">
        <li>
          <strong>{{ $t('NEPTUNE_CORPORATION') }}</strong
          >{{ $t('CEO') }} {{ $t('CEO_NAME') }}
        </li>
        <li>
          <strong>{{ $t('ADDRESS') }}</strong>
          <span v-html="$t('ADDRESS_DETAIL')"></span>
        </li>
        <li>
          <strong>{{ $t('TEL') }}</strong> +82 70 5038 0350
        </li>
        <li>
          <strong v-html="$t('BUSINESS_REGISTRATION_NUMBER')"></strong> 806 88
          00032
        </li>
        <li>
          <strong v-html="$t('MAIL_SALES_NUMBER')"></strong> 제 2016 성남분당
          0901
        </li>
        <li>
          <strong>{{ $t('SUPPORT_EMAIL') }}</strong
          >support@talkplus.io
        </li>
      </ul>
      <div class="footer-links">
        <a :href="$t('TERMS_URL_TERMS')" target="_blnak">{{ $t('TERMS') }}</a>
        <a :href="$t('TERMS_URL_PRIVACY')" target="_blnak">{{
          $t('PRIVACY_POLICY')
        }}</a>
        <a :href="$t('SERVER_STATUS_URL')" target="_blank">{{
          $t('SERVER_STATUS')
        }}</a>
      </div>
      <div class="copy-right" v-html="$t('FOOTER_8')"></div>
    </div>
  </v-footer>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.v-footer {
  background-color: transparent;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  font-size: 10px;
}
.footer-wrap {
  padding: 20px 24px 24px 24px;
  color: #aaa;
  font-family: 'Apple SD Gothic Neo', 'Spoqa Han Sans', sans-serif;
}
.footer-info {
  color: #aaa;
  padding: 0;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: -0.3px;
  li {
    line-height: 1.4;
  }
  strong {
    font-weight: 700;
    padding-right: 4px;
  }
}
.footer-links {
  margin: 12px 0 0 -12px;
  a {
    color: #aaa;
    text-decoration: underline;
    margin-left: 12px;
  }
}
</style>
